/**
 *
 */

import React from 'react'
import styled from 'styled-components'

/* Styles */
const Section = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background-color: #ffffff;
  text-align: center;
  padding: 20px 0px;
  @media (min-width: 768px) {
    padding: 100px 0px;
  }
`
const Paragraph = styled.p`
  font-weight: 700;
  font-size: 18px;
  text-align: justify;
  padding: 15px 10px;
  margin: 0px;
  @media (min-width: 768px) {
    font-size: 24px;
    max-width: 900px;
    width: 100%;
  }
`
const CiteContainer = styled.div`
  text-align: right;
  margin-right: 10px;
  font-size: 16px;
  width: 100%;
  @media (min-width: 768px) {
    font-size: 18px;
    max-width: 900px;
  }
`
const SectionComment = () => {
  return (
    <Section>
      <Paragraph>
        “MySchedulr is an application that business owners can use to manage and
        organize the scheduling of services offered, along with all the
        appointments/classes that have been booked by their customers.”
      </Paragraph>
      <CiteContainer>
        <cite>-Sierra E. Project Manager</cite>
      </CiteContainer>
    </Section>
  )
}

export default SectionComment
