/**
 *
 */

import React from 'react'
import styled from 'styled-components'

/* Assets */
import Logo from '../../images/Logo.webp'

/* Components */
import Button from '../Button'

/* Styles */
const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  padding-top: 30px;
`

const Figure = styled.figure`
  width: 50%;
  max-width: 300px;
  padding-left: 10px;
  @media (min-width: 768px) {
    padding-left: 92px;
  }
`;
const Img = styled.img`
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  width: 50%;
  height: 100%;
  padding-right: 10px;
  @media (min-width: 768px) {
    padding-right: 72px;
  }
`

const Head = () => {
  return (
    <Header>
      <Figure>
        <Img src={Logo} alt="FOS Bookings" />
      </Figure>
      <ButtonContainer>
        <Button>Start free now</Button>
      </ButtonContainer>
    </Header>
  )
}

export default Head
