/**
 *
 */

import React from 'react'

/* Sections */
import SectionHero from '../container/SectionHero'
import SectionComment from '../container/SectionComment'
import SectionZoomIntegrations from '../container/SectionZoomIntegrations'
import SectionProvide from '../container/SectionProvide'
import SectionIntegrations from '../container/SectionIntegrations'
import Footer from '../container/Footer'

/* Styles */
import '../styles/index.css'

const Index = () => {
  return (
    <body>
      <SectionHero />
      <SectionComment />
      <SectionZoomIntegrations />
      <SectionProvide />
      <SectionIntegrations />
      <Footer />
    </body>
  );
}

export default Index
