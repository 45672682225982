/**
 *
 */

import React from 'react'
import styled from 'styled-components'

/* Assets */
import FooterLogo from '../../images/FooterLogo.webp'

/* Styles */
const Footer = styled.footer`
  width: 100%;
  height: 100%;
  background-color: #031b13;
  color: #6b877e;
`
const FooterContainer = styled.div`
  width: 100%;
  height: 100%;
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
`
const ListContainer = styled.ul`
  margin: 20px 0px;
  padding-left: 10px;
  padding-top: 10px;
`
const ListItem = styled.li`
  font-size: 16px;
  font-weight: 700;
  margin: 10px 0px;
  cursor: pointer;
`
const Figure = styled.figure`
  width: 80%;
  margin-left: 10px;
  @media (min-width: 768px) {
    width: 100%;
    margin: 0px;
    max-width: 364px;
  }
`
const Image = styled.img`
  width: 100%;
`
const Text = styled.span`
  display: block;
  padding: 20px 0px;
  width: 100%;
  text-align: center;
`

const Link = styled.a`
  color: #6b877e;
`
const SectionFooter = () => {
  return (
    <Footer>
      <FooterContainer>
        {/* <ListContainer>
          <ListItem>Learn More</ListItem>
          <ListItem>Help</ListItem>
        </ListContainer> */}
        {/* <ListContainer>
          <ListItem>Company</ListItem>
          <ListItem>Terms of Service</ListItem>
          <ListItem>Data Processing Terms</ListItem>
          <ListItem>API Terms</ListItem>
        </ListContainer> */}
        <ListContainer>
          <ListItem>
            <Link href="https://newfold.com/privacy-center" target="_blank">
              Privacy
            </Link>
          </ListItem>
          {/* <ListItem>Abuse</ListItem> */}
          <ListItem>
            <Link
              href="https://www.newfold.com/privacy-center/addendum-for-california-users"
              target="_blank"
            >
              Do Not Sell My Personal Information
            </Link>
          </ListItem>
        </ListContainer>
        <Figure>
          <Image src={FooterLogo} />
        </Figure>
      </FooterContainer>
      <Text>© 2021 MySchedulr. All Rights Reserved</Text>
    </Footer>
  );
}

export default SectionFooter
