/**
 *
 */

import React from 'react'
import styled from 'styled-components'

/* Components */
import Header from '../../components/Header'
import Button from '../../components/Button'

/* Assets */
import Hero from '../../images/Hero.webp'
import Points from '../../images/Points.webp'

/* Styles */
const Section = styled.section`
  width: 100%;
  background-color: #edfdf7;
  @media (min-width: 768px) {
    height: 100%;
  }
`
const Title = styled.h1`
  font-size: 36px;
  color: #13254a;
  font-weight: 700;
  padding-left: 10px;
  @media (min-width: 768px) {
    padding-left: 90px;
  }
`
const Paragraph = styled.p`
  font-size: 18px;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 420px;
  text-align: justify;
  @media (min-width: 768px) {
    padding-left: 90px;
    padding-right: 0px;
    font-size: 24px;
  }
`
const ButtonContainer = styled.div`
  margin-left: 10px;
  @media (min-width: 768px) {
    padding-left: 90px;
  }
`
const Figure = styled.figure`
  width: 100%;
  max-width: 800px;
`
const Image = styled.img`
  width: 100%;
`
const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    margin-top: 70px;
    justify-content: space-between;
    align-items: flex-start;
  }
`
const TitleAndParagraphContainer = styled.div`
  @media (min-width: 768px) {
    margin-top: 90px;
  }
`
const ImagePoints = styled.img`
  width: 100%;
  max-width: 130px;
  margin-left: 10px;
  @media (min-width: 768px) {
    padding-left: 90px;
  }
`
const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`
const SectionHero = () => {
  return (
    <Section>
      <Header />
      <ContentContainer>
        <TitleAndParagraphContainer>
          <Title>Online Scheduling, Made Simple</Title>
          <Paragraph>
            We have created an online scheduling solution that easily integrates
            with your website and domain. Manage your own bookings page that
            will give your clients a simple view of your availability so they
            can schedule their appointments with ease!
          </Paragraph>
          <ButtonContainer>
            <Button>Let’s get booking!</Button>
          </ButtonContainer>
        </TitleAndParagraphContainer>
        <Figure>
          <Image src={Hero} />
        </Figure>
      </ContentContainer>
      <Figure>
        <ImagePoints src={Points} />
      </Figure>
    </Section>
  )
}

export default SectionHero
