/**
 *
 */

import React from 'react'
import styled from 'styled-components'

/* Styles */
const ButtonStyled = styled.button`
  background-color: #0076df;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 16px 12px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
`;

const Button = ({ children, ...props }) => (
  <ButtonStyled {...props}>{children}</ButtonStyled>
);

export default Button