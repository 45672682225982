/**
 *
 */

import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo, faHistory, faLaptop } from '@fortawesome/free-solid-svg-icons'

/* Assets */
import ZoomIntegrations from '../../images/ZoomIntegrations.webp'
import data from './data.json'

/* Styles */
const Section = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 20px 0px;
  width: 100%;
  height: 100%;
  @media (min-width: 768px) {
    height: 100vh;
    flex-direction: row;
    justify-content: space-around;
  }
`
const Figure = styled.figure`
  width: 100%;
  margin: 10px;
  max-width: 700px;
`
const Image = styled.img`
  width: 100%;
`
const ContentContainer = styled.div`
  padding: 0px 10px;
`
const Title = styled.h2`
  font-size: 28px;
  font-weight: 700;
`
const Paragraph = styled.p`
  font-size: 18px;
  text-align: justify;
  max-width: 500px;
`
const DetailsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 20px 0px;
  @media (min-width: 768px) {
    justify-content: flex-start;
  }
`
const Subtitle = styled.h4`
  margin: 0px;
  font-size: 20px;
  font-weight: 700;
  margin-top: 3px;
`
const DescriptionContainer = styled.div`
  margin-left: 10px;
`
const DescriptionParagraph = styled.p`
  margin: 0px;
  margin-top: 3px;
  font-size: 16px;
  text-align: justify;
  font-weight: 300;
  color: #6c6c6c;
  max-width: 450px;
`;
const SectionZoomIntegrations = () => {
  return (
    <Section>
      <Figure>
        <Image src={ZoomIntegrations} alt="Zoom Integrations" />
      </Figure>
      <ContentContainer>
        <Title>Schedule Zoom meetings with MySchedulr.</Title>
        <Paragraph>
          Our Zoom integration makes it easy to start virtual meetings,
          webinars, and events when a client schedules an appointment.{" "}
        </Paragraph>
        {data.map((item) => (
          <DetailsContainer>
            <FontAwesomeIcon className="fa-2x" icon={item.id == 1 ? faVideo : item.id == 2 ? faHistory : faLaptop} color="#0076DF" />
            <DescriptionContainer>
              <Subtitle>{item.title}</Subtitle>
              <DescriptionParagraph>
                {item.description}
              </DescriptionParagraph>
            </DescriptionContainer>
          </DetailsContainer>
        ))}
      </ContentContainer>
    </Section>
  );
}

export default SectionZoomIntegrations
