/**
 *
 */

import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt, faCreditCard } from "@fortawesome/free-solid-svg-icons";

/* Styles */
const Section = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  backgrond-color: #ffffff;
`
const Title = styled.h2`
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 0px;
`
const Paragraph = styled.p`
  font-size: 18px;
  text-align: center;
  max-width: 600px;
  padding: 0px 10px;
`
const Subtitle = styled.h4`
  margin: 0px;
  font-size: 20px;
  font-weight: 700;
  margin-top: 15px;
  max-width: 300px;
  text-align: center;
`;
const DescriptionParagraph = styled.p`
  margin: 0px;
  margin-top: 3px;
  font-size: 16px;
  text-align: center;
  font-weight: 300;
  color: #6c6c6c;
  max-width: 300px;
`
const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
`
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`
const SectionIntegrations = () => {
  return (
    <Section>
      <Title>Our Integrations</Title>
      <Paragraph>
        Make the scheduling process seamless with these tool applications.
      </Paragraph>
      <Container>
        <CardContainer>
          <FontAwesomeIcon className="fa-2x" icon={faSyncAlt} color="#0076DF" />
          <Subtitle>Sync Calendars Via Google Calendar or iCloud</Subtitle>
          <DescriptionParagraph>
            Connect your schedule to your personal calendar to view your
            upcoming appointments and events all in one place.{" "}
          </DescriptionParagraph>
        </CardContainer>
        <CardContainer>
          <FontAwesomeIcon
            className="fa-2x"
            icon={faCreditCard}
            color="#0076DF"
          />
          <Subtitle>Collect Payments</Subtitle>
          <DescriptionParagraph>
            Accept online payments for your appointments through PayPal.
          </DescriptionParagraph>
        </CardContainer>
      </Container>
    </Section>
  );
}

export default SectionIntegrations
